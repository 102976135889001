<template>
  <div class="layout-container">
    <layoutHeader></layoutHeader>
    <div class="layoutMain">
      <router-view></router-view>
    </div>
    <layoutFooter></layoutFooter>
  </div>
</template>

<script>
import layoutHeader from "@/components/header/index.vue";
import layoutFooter from "@/components/footer/index.vue";
export default {
  name: "layout",
  components: {
    layoutHeader,
    layoutFooter,
  },
  data() {
    return {};
  },
};
</script>

<style lang='scss' scoped>
.layout-container {
  width: 100%;
  .layoutMain {
    min-height: calc(100vh - 468px); //页头页脚高度
    min-width: 1200px;
  }
}
</style>
