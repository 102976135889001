import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import animated from "animate.css";
import "element-ui/lib/theme-chalk/index.css";
// 按需导入elementUi
import customElement from "@/components/plugins/element.js";
Vue.use(customElement);
import { Message, MessageBox, Notification, Loading } from "element-ui";
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.use(Loading.directive);

import axios from "@/utils/request";
window.axios = axios;

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta.title == undefined
      ? "洛阳济品机械有限公司"
      : "洛阳济品机械有限公司-" + to.meta.title;
  next();
});
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () { };
  }
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
