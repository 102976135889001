<template>
  <div class="header">
    <div class="logo">
      <img
        src="@/assets/img/logo.png"
        alt="logo"
        title="洛阳济品机械有限公司"
      />
    </div>
    <div class="menu">
      <div
        class="menuItem"
        :class="{ active: activeIndex == index }"
        v-for="(item, index) in menuList"
        :key="index"
        @click="handleMenu(item, index)"
      >
        <div v-if="activeIndex == index">
          <span>{{ item.title }}</span>
        </div>
        <span v-else>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeIndex: 0,
      menuList: [
        {
          title: "首页",
          path: "/index",
        },
        {
          title: "行业方案",
          path: "/industry",
        },
        {
          title: "产品中心",
          path: "/product",
        },
        {
          title: "关于我们",
          path: "/aboutUs",
        },
      ],
    };
  },
  watch: {
    $route(from, to) {
      this.getRouter();
    },
  },
  methods: {
    handleMenu(item, index) {
      this.activeIndex = index;
      if (this.$route.path == item.path) return;
      this.$router.push(item.path);
      // const routeUrl = this.$router.resolve({ path: item.path });
      // window.open(routeUrl.href, "_blank");
    },
    getRouter(path) {
      let route = path ? path : this.$route.path;
      this.menuList.forEach((item, index) => {
        if (!route) {
          return (this.activeIndex = 0);
        }
        if (item.path) {
          if (route.search(item.path) != -1) {
            return (this.activeIndex = index);
          }
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  width: 1200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 195px;
    img {
      width: 100%;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 650px;

    .menuItem {
      color: #333;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      &.active {
        color: #1d82a0;
        border-bottom: thin solid #1d82a0;
      }
    }
  }
}
</style>
