import request from "@/utils/request";
const uid = 'dbaddffd-31f9-4d91-9592-de5b242a48ef';
//获取轮播图
export function getBanner() {
    return request({
        url: "/api/getBannerInfo",
        method: "post",
        data: { createBy: uid }
    });
}

//获取企业信息
export function getCompanyInfo() {
    return request({
        url: "/api/getCompanyInfo",
        method: "post",
        data: { createBy: uid }
    });
}
//留言接口地址
export function postMessage(data) {
    return request({
        url: "/api/leaveContent",
        method: "post",
        data: { ...data, createBy: uid },
    });
}

//获取产品列表
export function getProductList() {
    return request({
        url: "/api/getProductInfo",
        method: "post",
        data: { createBy: uid }
    });
}
//获取产品信息
export function getProductInfo(id) {
    return request({
        url: "/api/getProductInfoById/" + id,
        method: "get",
    });
}

