import Vue from "vue";
import Vuex from "vuex";
import { getCompanyInfo } from "@/api/monitor";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    companyInfo: {},
  },
  getters: {},
  mutations: {},
  actions: {
    getCompanyInfo() {
      const info = new Promise((resolve, reject) =>
        getCompanyInfo()
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
      );
      info.then((res) => {
        this.state.companyInfo = res;
      });
    },
  },
  modules: {},
});
