import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  Pagination,
  Tabs,
  TabPane,
  Backtop,
  Carousel,
  CarouselItem,
  Breadcrumb,
  BreadcrumbItem
} from "element-ui";

const customElement = {
  install: function (Vue) {
    Vue.component("ElButton", Button);
    Vue.component("ElForm", Form);
    Vue.component("ElFormItem", FormItem);
    Vue.component("ElInput", Input);
    Vue.component("ElPagination", Pagination);
    Vue.component("ElTabs", Tabs);
    Vue.component("ElTabPane", TabPane);
    Vue.component("ElBacktop", Backtop);
    Vue.component("ElCarousel", Carousel);
    Vue.component("ElCarouselItem", CarouselItem);
    Vue.component("ElBreadcrumb", Breadcrumb);
    Vue.component("ElBreadcrumbItem", BreadcrumbItem);
  },
};
export default customElement;
