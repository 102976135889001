import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/layout/layout.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    meta: { title: '首页' },
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    meta: { title: '关于我们' },
    component: () => import("@/views/aboutus/aboutUs.vue"),
  },
  {
    path: "/product",
    name: "product",
    meta: { title: '产品中心' },
    component: () => import("@/views/product/product.vue"),
  },
  {
    path: "/product/productDetail/:id",
    name: "productDetail",
    meta: { title: '产品详情' },
    component: () => import("@/views/product/productDetail.vue"),
  },

  {
    path: "/industry",
    name: "industry",
    meta: { title: '行业方案' },
    component: () => import("@/views/industry/industry.vue"),
  },
];

const router = new VueRouter({
  mode: "history", // 去掉url中的#
  routes,
});

export default router;
