<template>
  <div id="footBox">
    <div class="foot">
      <div class="info">
        <div class="title">{{ $store.state.companyInfo.name }}</div>
        <div class="tel">邮编：471000</div>
        <div class="tel">电话：{{ $store.state.companyInfo.tel }}</div>
        <div class="email">邮箱：{{ $store.state.companyInfo.email }}</div>
        <div class="address">
          地址：{{ $store.state.companyInfo.address }}
        </div>
      </div>
      <div class="form">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名:">
            <el-input v-model="form.name" type="text" placeholder="请输入姓名" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input v-model="form.phone" type="text" placeholder="请输入电话" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="留言:">
            <el-input v-model="form.content" type="textarea" :rows="3" placeholder="请输入留言信息" maxlength="20"
              show-word-limit></el-input>
          </el-form-item>
          <el-form-item>
            <div class="submit" @click="handleChangeFan">提交</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="version">
      <a href="https://www.beian.gov.cn/" target="_blank">豫ICP备2023011779号</a>
    </div>
  </div>
</template>

<script>
import { postMessage } from "@/api/monitor";
import { IsEmpty } from "@/utils/commonMethods";
export default {
  name: "index",
  data() {
    return {
      form: {
        name: "",
        phone: "",
        content: "",
      },
    };
  },
  methods: {
    //在线留言信息提交
    handleChangeFan() {
      let reg = /^1[3456789]\d{9}$/;
      if (IsEmpty(this.form.name) || IsEmpty(this.form.phone) ||IsEmpty(this.form.content)) {
        this.$message.error('请填写完整信息');
        return;
      }
      if (!reg.test(this.form.phone)) {
        this.$message({
          message: "请输入正确的手机号码",
          type: "error",
        });
        return;
      }
      postMessage(this.form).then((res) => {
        const { code, data, msg } = res;
        if (code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "提交失败",
            type: "error",
          });
        }
      });
      this.$refs["form"].resetFields();
    },

  },
};
</script>

<style lang='scss' scoped>
#footBox {
  width: 100%;
  background-color: #151515;

  .foot {
    width: 1200px;
    padding-top: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-color: #151515;

    .info {
      .title {
        font-size: 20px;
        color: #fff;
        line-height: 32px;
        text-align: left;
        margin-bottom: 10px;
      }

      .address,
      .tel,
      .email {
        color: #fff;
        text-align: left;
        line-height: 32px;
        margin-bottom: 10px;
        font-size: 14px;
      }

    }

    .form ::v-deep {
      width: 400px;

      .submit {
        width: 180px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #ffffff;
        border-radius: 15px;
        margin: 0 auto;
        cursor: pointer;
      }

      .el-form-item__label {
        color: #fff;
      }

      .el-input__inner,
      .el-textarea__inner {
        background-color: transparent !important;
      }

      .el-input__count {
        background-color: transparent !important;
      }
    }
  }

  .version {
    background-color: #000000;
    width: 100%;
    min-width: 1200px;
    line-height: 60px;
    text-align: center;
    color: #707070;
    font-size: 14px;

    a:hover {
      color: #409eff;
      text-decoration: underline;
    }
  }
}
</style>
